<template>
  <div :class="{ contain: true, mobile: isMobile }">
    <div class="banner">
      <v-img class="img" v-if="ban" :src="ban" cover alt="" />
    </div>
    <div class="infocard">
      <div class="d-flex flex-lg-row flex-column infoAvat">
        <div class="d-flex flex-lg-column align-start avat">
			<div class="avatar-box align-self-lg-start rounded-circle">
          <v-avatar
            :size="180"
			 style=" overflow:visible; position: relative;"
          >
            <v-img :src="src" alt="" />
			<img
					v-if="pageInfo.grade==3"
			  src="@/assets/icons/icon_v.png"
			  alt="V"
			  class="bigv"
			/>
          </v-avatar>
		</div>

          <div class="unflowbox" v-if="!isMobile && !pageInfo.followed&& !isSelf">
            <v-btn class="px-lg-2 unflow" @click="unflowBtn">{{$t('follow')}}</v-btn>

          </div>
          <v-btn
            v-if="!isMobile && pageInfo.followed"
            class="px-lg-2 isPcBtn flowed"
            @click="flowedBtn"
            >{{ $t('fansSubscrible') }}</v-btn
          >
        </div>
        <div class="d-flex flex-column infos ml-lg-8 mt-lg-12">
          <div class="showtip d-flex flex-lg-row justify-space-between">
            <span class="name" style="font-weight: bold;">{{ pageInfo.userName }}</span>
            <img
              v-if="!isSelf"
              class="hovershow mt-lg-5 mt-5"
              src="@/assets/icons/icon_complaint.png"
              alt=""
              width="24px"
              height="24px"
			  style="cursor: pointer;"
              @click="accPlaintBtn"
            />
          </div>
          <div class="no_copy">
            <span v-if="!isMobile" class="No">{{ pageInfo.did }}</span>
            <span v-if="isMobile" class="No">{{ pageInfo.did | didfilter }}</span>
            <v-img class="copy " :class="{click:isCopy}" src="@/assets/icons/icon_copy.png" @click="onCopyClick" />
			<img class="mr-2 mb-n1" style="margin-left: 50px;width: 18px;height: 19px;" src="@/assets/icons/icon_rili.png" alt="" />
			<span class="mt-2"  style="font-size:13px; ">{{ joinTime }}</span>
          </div>
          <div class="unflowbox1 mt-3" v-if="isMobile && !pageInfo.followed && !isSelf">
            <v-btn class="follow-btn unflow" @click="unflowBtn">{{$t('fansSubscrible')}}</v-btn>
          </div>
          <v-btn
            v-if="isMobile && pageInfo.followed"
            class="follow-btn flowed  mt-3"
            @click="flowedBtn"
            >{{$t('fansSubscrible')}}</v-btn
          >
          <div class="icon_url " style="margin-top:-3px;">
            <div class="icon-box  mt-1 " v-if="pageInfo.instagram && pageInfo.instagram!='null'">
               <a :href="Instagram" target="_blank" @click="mediaClick('instagram')">
                 <img
                class="mb-n2"
                style="margin-left: 0"
                src="@/assets/icons/icon_Instagram.png"
                alt=""
              />
              <span style="color:#270645">{{ decodeURIComponent(pageInfo.instagram) }}</span>
              </a>
             
            </div>
            <div class="icon-box  mt-1 media" v-if="pageInfo.twitter && pageInfo.twitter!='null'">
              <a :href="Twitter" target="_blank" @click="mediaClick('twitter')" >
                <img class="mr-2 mb-n2 " src="@/assets/icons/icon_twitter.png" alt="" />
                <span class="text-caption">{{ decodeURIComponent(pageInfo.twitterName) }}</span>
                     <img
               v-if="pageInfo.twitterVerify"
              class="mr-2 mb-n1"
              style="margin-left: 4px;width:16px;height:16px;"
              src="@/assets/icons/icon_verified.png"
              alt=""
            />
              </a>
            </div>
            <div class="icon-box  mt-1 media" v-if="pageInfo.youtube && pageInfo.youtube!='null'">
              <a :href="Youtube" target="_blank" @click="mediaClick('youtube')">
                <img class="mr-2 mb-n2" src="@/assets/icons/icon_youtube.png" alt="" />
                <span class="text-caption">{{ decodeURIComponent(pageInfo.youtube ) }}</span>
              </a>
            </div>
            <div class="icon-box  mt-1 media"  v-if="pageInfo.homepage && pageInfo.homepage!='null'">
              <a :href="Homepage" target="_blank" @click="mediaClick('homepage')">
                <img class="mr-2 mb-n2" src="@/assets/icons/icon_site.png" alt="" />
                <span class="text-caption">{{  decodeURIComponent(pageInfo.homepage) }}</span>
              </a>

            </div>
          </div>
          <div class="d-flex flex-column">
            <div class="desc pading-gap mb-lg-3 mt-lg-3" v-html="pageInfo.synopsis"></div>
          <div class="ingbox d-flex  flex-row" v-if="!isMobile">
            <div class="box  ml-1 mb-5" v-for=" item in imgList" :key="item.id">
              <img :src=item.src alt="" @click="openLargeImg(item)">	
            </div>	
				  </div>
            <div class="d-flex flex-lg-row flex-column">
              <div class="d-flex flex-row flex-between-center">
                <div>
                  <div class="num">{{ pageInfo.fansNumber }}</div>
                  <div class="sub">{{ $t('avatarAvatarInfoFans') }}</div>
                </div>
                <div class="px-0 px-lg-10 followers">
                  <div class="num">{{ pageInfo.followCount }}</div>
                  <div class="sub">{{ $t('fansSubscrible') }}</div>
                </div>
                <div>
                  <div class="num">{{ pageInfo.workCount }}</div>
                  <div class="sub">{{ $t('avatarlistWork') }}</div>
                </div>
              </div>
              <ScrollX>
                <div class="ingbox d-flex  flex-row" v-if="isMobile">
                  <div class="box  ml-1 mb-5" v-for=" item in imgList" :key="item.id">
                    <img :src=item.src alt="" @click="openLargeImg(item)">	
                  </div>	
                </div>
              </ScrollX>
              <div class="footer ml-lg-16 d-flex flex-row" v-if="pageInfo.fansNumber>0">
                <div class="avatar-name mt-lg-3">{{ $t('avatarAvatarInfoFans') }}</div>
                <div class="mt-lg-2" style="position: relative; height: 30px">
                  <div
                    class="avatar"
                    :style="{ left: `${index * 18}px` }"
                    v-for="(info, index) of avatars"
                    :key="index"
					          @click="avatarClick"
                  >
                    <v-avatar size="28">
                      <!-- <v-img :src="info.src" alt="" /> -->
                      <v-img v-if="info.src == null" src="@/assets/default.png" alt="" />
                      <v-img v-else :src="info.src" alt="" />
                    </v-avatar>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog v-model="accountComplaint">
      <account-complaints @getOpen="openAccComp" :pageInfo="pageInfo"></account-complaints>
    </v-dialog>
    <v-dialog fullscreen v-model="openLargemg">
			<large-img ref="largeDialog" v-if="openLargemg" :productPic="productPic" 
				@getOpen="Open">
			</large-img>
		</v-dialog>
    <uComponents  ref="ucom"></uComponents>
  </div>
</template>

<script>
import AccountComplaints from "@/components/popup/accountComplaints.vue";
import { getFileSrc } from "@/utils/file";
import api from "@/api";
	import LargeImg from "@/components/largeImg.vue";
  import ScrollX from "@/components/scroll/ScrollX.vue";
  import defaultImg from "@/assets/default.png";
//
const AVATAR_KEY = "AVATAR";
const BANNER_KEY = "BANNER";
 const SYNOPSIS_KEY = "SYNOPSIS";

export default {
  name: "headerPage",
  components: { AccountComplaints,LargeImg,ScrollX },
  data: () => ({
    src: "",
    ban: "",
    accountComplaint: false,
    avatars: [],
    pageInfo: {},
    defaultImg:'',

	  isCopy: false,
    isSelf: false,
    Twitter:'',
    Youtube:'',
    Instagram:'',
    Homepage:'',
    //展示图片
				imgList:[
					//接口获取的三张图
				],
        	openLargemg:false,
				productPic:'',
				joinTime:''
  }),
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  filters: {
  	didfilter: function(value) {
		if(value && value.length > 24) {
			return value.substr(0,12)+"..."+value.substr(-12);
		} else {
			return "";
		}
  	}
  },
  mounted() {
    window.eventBus.$on('LangChange', this.onLangChange);
    this.getFans();
  },
  methods: {
      mediaClick(name){
       this.$gtag.event('个人主页-'+name, { 'event_category': 'Click', 'event_label': name })
    },
	  onLangChange() {
	  	this.avatars=[];
	  	this.imgList=[]
	  	
	    this.getFans();
	  },
	  
    //头部个人信息
    async getFans() {
      let params = {
        did: this.$store.state.did,
        chainType: this.$store.state.chainType,
        userAddress:this.$route.query.did

      };
      console.log("wxl -----------")
      console.log(params);

      let res = await api.home.createInfo(params);
      console.log("-------------")
      console.log(res)
      this.pageInfo = res.data;
      // 获取个人描述信息
      if(res.data.synopsisImage)
      {
        let imgLists = (res.data.synopsisImage).split(",");
        console.log(imgLists);
        imgLists.forEach(async (v) => {
         
          console.log(v)
          if ( v != '') {
            let imgsrc = await getFileSrc(SYNOPSIS_KEY, v);
           
            this.imgList.push({ src: imgsrc });
          }
        });
        console.log(this.imgList)
      }
      
 if(this.pageInfo.joinTime!=null){
		  let t = this.$t("otherpage_join")
		   let reg=new RegExp('【N】','g')//g代表全部
		 this.joinTime=t.replace(reg,this.pageInfo.joinTime);
	
    }
          //Instagram是否含有http / https
      if(this.pageInfo.instagram != null){
		  let instagram=this.pageInfo.instagram.toLowerCase();
        let newinstatgram=instagram.indexOf("instagram.com");
        let newinstatgram1=instagram.indexOf("https://instagram.com");
        let newinstatgram2=instagram.indexOf("http://instagram.com");
        if(newinstatgram>=0 || newinstatgram1>=0 || newinstatgram2>=0 ){
           this.Instagram = `${this.pageInfo.instagram}`
          
        }else{
          this.Instagram = `https://instagram.com/${this.pageInfo.instagram}`
        }
       if(newinstatgram == 0)
       {
         this.pageInfo.instagram = this.pageInfo.instagram.slice(14)
       }
       if(newinstatgram1 == 0)
       {
         this.pageInfo.instagram = this.pageInfo.instagram.slice(22)
       }
       if(newinstatgram2 == 0)
       {
         this.pageInfo.instagram = this.pageInfo.instagram.slice(21)
       }
       
       
      }
       //twitter是否含有http / https
      if(this.pageInfo.twitter != null){
        let newtwitter=this.pageInfo.twitter.indexOf("http");
        if(newtwitter==0){
          this.Twitter = `${this.pageInfo.twitter}`
          
        }
        if(newtwitter==-1){
          this.Twitter = `https://${this.pageInfo.twitter}`
         
        }
        let showTwitter=this.pageInfo.twitter.indexOf("twitter.com/");
        if(showTwitter == 0 )
        {
          this.pageInfo.twitter = this.pageInfo.twitter.slice(12)
        }
       
      }
      //youtube是否含有http / https
      if(this.pageInfo.youtube != null){
        let newyoutube=this.pageInfo.youtube.indexOf("http");
        if(newyoutube==0){
          this.Youtube = `${this.pageInfo.youtube}`
        }
        if(newyoutube==-1){
          this.Youtube = `https://${this.pageInfo.youtube}`
        }
         let showYoutube=this.pageInfo.youtube.indexOf("https://www.youtube.com/channel/");
        if(showYoutube == 0 )
        {
          this.pageInfo.youtube = this.pageInfo.youtube.slice(32)
        } 
      }

      //homepage是否含有http / https
      if(this.pageInfo.homepage != null){
        let newhomepage=this.pageInfo.homepage.indexOf("http");
        if(newhomepage==0){
          this.Homepage = `${this.pageInfo.homepage}`
        }
        if(newhomepage==-1){
          this.Homepage = `https://${this.pageInfo.homepage}`
        }
      }
      if(this.pageInfo.synopsis)
      this.pageInfo.synopsis = this.pageInfo.synopsis.replace(/\n/g,"<br/>")
      this.$emit('getName',this.pageInfo.userName);
      //头像
		if (this.pageInfo.profilePhoto) {
			this.src = await getFileSrc(AVATAR_KEY, this.pageInfo.profilePhoto);
		} else {
			this.src = defaultImg;
		}
		
      //背景图
		if (this.pageInfo.homeBackground&&this.pageInfo.homeBackground.length>4) {
			this.ban = await getFileSrc(BANNER_KEY, this.pageInfo.homeBackground);
			if (this.ban == "null") this.ban = "";
		}else{
				this.ban = defaultImg
		}
		
      console.log("背景图" + this.ban);
      //fans小头像
      let fanSrc = res.data.fans;
     
      if (fanSrc.length > 0) {
        fanSrc.forEach(async (v) => {
          console.log(v);
          if(v != null && v.length == 46)
          {
            let fansrc = await getFileSrc(AVATAR_KEY, v);
          this.avatars.push({ src: fansrc });

          }else{
            this.avatars.push({ src: defaultImg });
          }

        });
      }
      this.isSelf = this.$store.state.did==this.pageInfo.did
    },
    //点击关注
    async unflowBtn() {
       this.$gtag.event('个人主页-取消关注', { 'event_category': 'Click', 'event_label': '取消关注' })
      let params = {
        chainType: this.$store.state.chainType,
        userAddress: this.$route.query.did,
        fansAddress: this.$store.state.did,
      };
      let res = await api.home.addDo(params);
      if (res.success === true) this.pageInfo.followed = true;
    },
    //取消关注
    async flowedBtn() {
       this.$gtag.event('个人主页-关注', { 'event_category': 'Click', 'event_label': '关注' })
      let params = {
        chainType: this.$store.state.chainType,
        userAddress: this.$route.query.did,
        fansAddress: this.$store.state.did,
      };
      let res = await api.home.removeDo(params);
      if (res.success === true) this.pageInfo.followed = false;
    },
    accPlaintBtn() {
       this.$gtag.event('个人主页-投诉', { 'event_category': 'Click', 'event_label': '投诉' })
      this.accountComplaint = true;
    },
    openAccComp(e) {
      // this.$toast("info", msg);
      this.accountComplaint = e;
    },
    //查看大图
			openLargeImg(item) {
				this.productPic = item.src
				this.openLargemg = true;
				// this.$refs.largeDialog.playVideo();
			},
			//弹框关闭后获取open值
			Open(e) {
				this.openLargemg = e;
			},
	onCopyClick() {
      this.$gtag.event('他人主页-钱包地址', { 'event_category': 'Click', 'event_label': '钱包地址' })
		var input = document.createElement('input')
		input.value = this.$route.query.did;
		document.body.appendChild(input);
		input.select();
		document.execCommand('copy');
		document.body.removeChild(input);
		this.isCopy = true;
		setTimeout(() => this.isCopy = false, 100);
	},
	onMediaClick(val) {
		window.open(val, '_blank');
	},
	avatarClick() {
    if (this.pageInfo.did == this.$store.state.did) {
      this.$router.push({ name: "Fans" })
    }
	}
  },
};
</script>

<style lang="scss" scoped>
  a{
    text-decoration: none;
    vertical-align: middle;

    img,span{
      display:inline-block;
    }
    span{
      color: #270645 !important;
    }
  }
.flex-between-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-center-start {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.flex-start-center {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.pading-gap {
  padding: 0;
}

.contain {
  padding: 0;
  margin: 0;

  .banner {
    height: 301px!important;
    // margin-top: 31px;
    .img {
      height: 100%;
      width: 100%;
    border-radius: 15px;
    }
  }

  .infocard {
    position: relative;
    top: -50px;
    padding: 14px 0;
    .infoAvat {
      .avat {
        .unflowbox {
          width: 86px;
          height: 41px;
          box-sizing: border-box;
          padding: 2px;
          background-image: linear-gradient(
            0deg,
            #d202fd 0%,
            #a538fe 26%,
            #776eff 51%,
            #01facd 100%
          );
          border-radius: 20px;
          position: relative;
          top: -20px;
          left: 50px;

          .unflow {
            width: 100%;
            height: 100%;
            border: none;
            border-radius: 20px;
            background: #fff;
           font-family:Helvetica;
            font-size: 15px;
            font-weight: bold;
            font-stretch: normal;
            letter-spacing: 0;
            color: #270645;
          }
        }

        .flowed {
          width: 121px;
          height: 41px;
          background-color: #270645;
          border-radius: 20px;
         font-family:Helvetica;
          font-size: 15px;
          font-weight: bold;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #ffffff;
        }
        .isPcBtn {
          position: relative;
          top: -20px;
          left: 35px;
        }
      }
      .infos {
        .showtip {
          min-width: 997px;
        }
        .unflowbox1 {
          width: 86px;
          height: 41px;
          box-sizing: border-box;
          padding: 2px;
          background-image: linear-gradient(
            0deg,
            #d202fd 0%,
            #a538fe 26%,
            #776eff 51%,
            #01facd 100%
          );
          border-radius: 20px;
          margin: 0 auto;

          .unflow {
            width: 100%;
            height: 100%;
            border: none;
            border-radius: 20px;
            background: #fff;
           font-family:Helvetica;
            font-size: 15px;
            font-weight: bold;
            font-stretch: normal;
            letter-spacing: 0;
            color: #270645;
          }
        }

        .flowed {
          width: 121px;
          height: 41px;
          background-color: #270645;
          border-radius: 20px;
         font-family:Helvetica;
          font-size: 15px;
          font-weight: bold;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #ffffff;
        }
      }
    }

    .name {
     font-family:Helvetica;
      font-size: 40px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
    }

    .no_copy {
      .No {
        font-family:Helvetica;
        font-size: 11px;
        color: #270645;
        padding-top: 4px;
      }

      .copy {
        width: 13px;
        height: 15px;
        display: inline-block;
        vertical-align: middle;
        margin-left: 8px;
		cursor: pointer;
      }
      .click {
      	margin-top: 5px;
      }
    }

    .icon_url {
      margin: 25px 0 -5px;

      .icon-box {
        display:inline-block;
				word-wrap:break-word;
				word-break:break-all;
        vertical-align: middle;
        margin: 0 24px 0 0;

        span {
          display:inline;
          font-family:Helvetica;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 24px;
          letter-spacing: 0px;
          color: #000000;
        }

        img {
          display: inline;
          width: 22px;
          height: 22px;
          margin: 0 8px 0 0;
        }
      }

	  .media {
	  	cursor: pointer;
	  }
    }

    .desc {
      // display: -webkit-box;
			overflow: hidden;
			font-size: 13px;
			white-space: normal !important;
			text-overflow: ellipsis;
			word-wrap: break-word;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
      // text-overflow: -o-ellipsis-lastline;
      // overflow: hidden;
      // text-overflow: ellipsis;
      // display: -webkit-box;
      // word-wrap: break-word;
      // -webkit-line-clamp: 3;
      // line-clamp: 3;
      // -webkit-box-orient: vertical;
      // font-family:Helvetica;
      // font-size: 13px;
      // color: #270645;
      // // margin-top: 14px;
      // padding-left: 0;
      // // width: 851px;
      // font-weight: normal;
      // font-stretch: normal;
      // letter-spacing: 0px;
			// white-space: normal !important;
    }
    .ingbox{
      max-width:996px;
			overflow-x: auto ;
			overflow-y: hidden;
			white-space: nowrap;
			.box{
				height:300px;
				// width:auto;
				margin-right:5px;
				
				img{
					width:auto;
					height:300px;
					display:block
					// object-fit: contain;
						
				}
			}
    }
    

    .followers {
      padding: 0 46px;
    }

    .num {
     font-family:Helvetica;
      font-size: 15px;
      color: #270645;
      font-weight: bold;
    }

    .sub {
      font-family:Helvetica;
      font-size: 14px;
      color: #766983;
    }

    .line {
      border-bottom: solid 1px #e3e3e3;
      margin: 18px 0;
    }

    .follow-btn {
      width: auto;
      //   height: 41px;
      //   padding: 0 15px;
      //   color: #270645;
      //   font-size: 15px;
      //   border-radius: 20px;
      margin: 0 auto;
    }

    .footer {
      .avatar-name {
       font-family:Helvetica;
        font-size: 14px;
        color: #270645;
        font-weight: bold;
        padding-right: 14px;
      }

      .avatar {
        position: absolute;
        left: 0;
        top: 0;
        background: white;
        padding: 2px;
        border-radius: 50%;
		// cursor: pointer;
      }
    }
  }

  &.mobile {
    width: 100%;
    
    .hovershow {
        display: none;
    }

    .banner {
      // width: 320px;
      height: 180px;
      // margin-left: -40px;
      // margin:31px 0 0 0 ;
      background-color: #c9c9c9;
      .img {
        height: 100%;
      }
    }

    .infocard {
      .infoAvat {
        width: 100%;

        .avat {
          margin: -30px auto 0;
        }

        .infos {
          text-align: center;
          .showtip {
            min-width: 100%;
          }

          span {
            text-align: center;
          }
        }
      }

      .desc {
        width: 100%;
      }
      .ingbox{
        margin-top:25px;
        margin-bottom:30px;
        // width:100%;
        overflow-x: auto ;
			  overflow-y: hidden;
			  white-space: nowrap;
        .box{
          height:350px;
          // width:350px;
          img{
            //width:100%;
            width:auto;
            height:100%;
            // object-fit: contain;
          }
        }
      }
      .icon_url {
      margin: 25px 0 24px;
      text-align: left;

      .icon-box {
        display:inline-block;
				word-wrap:break-word;
				word-break:break-all;
        vertical-align: middle;
        margin: 0 24px 0 0;

        span {
          display:inline;
          font-family:Helvetica;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 24px;
          letter-spacing: 0px;
          color: #000000;
        }

        img {
          display: inline;
          width: 22px;
          height: 22px;
          margin: 0 8px 0 0;
        }
      }

	  .media {
	  	cursor: pointer;
	  }
    }
    }

  }
}
	.avatar-box {
			position: relative;
			top: -40px;
			left: 0;
			border: 6px white solid;
		}
.bigv{
	  width:40px;
	  height:40px;
	    position: absolute;
	  right: -4px;
	  bottom: 1px;
	}
</style>
