<template>
  <div :class="{ content: true, mobile: isMobile }">
    <div class="top-right d-flex flex-row justify-space-between" style="width:100%">
        <div class="top_tab">

           <span class="btn mr-8" :class="{ btn2: onSaleClick }" @click="onSaleBtn"
            >{{ $t("Onsale") }}</span
          > 
           <span class="btn mr-8" :class="{ btn2: createClick }" @click="createBtn"
            >{{ $t("Created") }}</span
          >        
            <span class="btn mr-8" :class="{ btn2: ownerClick }" @click="ownerBtn"
            >{{ $t("Owned") }}</span
          > 
            <span class="btn " :class="{ btn2: collectClick }" @click="mode_click('collection')"
            >{{ $t("Collection") }}</span
          > 
        </div>
    
    </div>
    <div class="explain">
         <div class="nftexplain d-flex flex-row align-center" v-if="isshownft" >
                <span>{{ $t("create_nft_explain") }}</span>
          </div>
          <div class="collectexplain d-flex flex-row align-center"  v-if="isshowcollect">
                <span>{{ $t("create_collection_explain") }}</span>
              </div>
    </div>
    <div class="main">
      <div class="collect" :class="{collectionList:showModel=='collection'}" v-if="showModel == 'collection'">
         <div v-if="empty" class="empty">{{$t('EmptyHere')}}</div>
        <div class="card" v-for="(item, index) in collects" :key="index">
          <collect-card
            :pageValue="item"
            :collectsource="collectsource" 
            :hash="item.imgUrl"
            :showAvatar="showAvatar"
            :did="did"
            @imgClick="imgClick(item)"
            collectfrom="otherpage"
          ></collect-card>
        </div>
      </div>

      <div class="collect" :class="{nftList:showModel=='nft'}" v-if="showModel == 'nft'">
         <div v-if="empty" class="empty">{{$t('EmptyHere')}}</div>
        <div class="card" v-for="(item, index) in nfts" :key="index">
          <!-- <Card v-for="(item, i) in list" :key="i+ref_index" v-scroll="onScroll" type="sell" :value="item" /> -->
          <Card :type="cardType" :hash="item.hash" :value="item" :owerfrom="owerfrom" v-scroll="onScroll" />
        </div>
        
      </div>
       <loading :isShowLoading="isShowLoading"></loading>
       
    </div>
  </div>
</template>

<script>
import SortSelect from "@/components/SortSelect.vue";
import CardList from "@/components/workCard/CardList.vue";
import CollectCard from '@/components/workCard/CollectCard.vue';
import Card from "@/components/workCard/Card.vue";
import api from "@/api";
import Loading from "@/components/loading.vue";


export default {
  name: "tabsPage",
  components: { CardList,CollectCard, SortSelect,Card,Loading },
  data: function () {
    return {
      isShowLoading: false,
      pageNumber: 1,
      pageSize: 20,
      totalPage: false,
      activeTab: undefined,
      showModel: "nft", // collection
      nftImage: require("@/assets/icons/icon_cj_nft.png"),
      collectionImage: require("@/assets/icons/icon_cj_collection_g.png"),
      tablist: [
        { value: "0", label: this.$t("otherpageTabsPageAll") },
        { value: "3", label: this.$t("otherpageTabsPageArtwork") },
        { value: "4", label: this.$t("amCreateCollectibles") },
        { value: "5", label: this.$t("createCategoriesPhotography") },
        { value: "7", label: this.$t("amCreateVideo") },
        { value: "9", label: this.$t("otherpageTabsPageHotResale") },
      ],
      activeSort: "",
      selectlist: [
        { value: "", label: this.$t("otherpageTabsPageAll") },
        { value: "1", label: this.$t("otherpageTabsPageOnsale") },
        { value: "0", label: this.$t("workCardSoldOut") },
      ],
      list: [],
      collects: [],
      nfts: [],
      showAvatar:false,
      pageInfo: {},
       empty:false,
      createClick :false,
      onSaleClick : true,
      ownerClick:false,
      collectClick:false,
      isshowcollect:false,
       isshownft:false,
       filter:9,
       cardType:'other',
       collectsource:'otherpage',
       did:'',
       owerfrom:"firspublish"
    };
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
    // handler: function () {
    //   return {
    //     activeTab: this.activeTab,
    //     activeSort: this.activeSort,
    //   };
    // },
  },
  watch: {
    // handler: {
    //   handler: function () {
    //     this.pageNumber = 1;
    //     this.list = [];
    //     this.users = [];
    //     this.getOtherpageList();
    //   },
    //   deep: true,
    // },
  },
  mounted() {
     let mode = localStorage.getItem("selectMode")
    if( mode && mode == 0)
    {
      this.mode_click("nft")
    }else if(mode && mode == 1){
       this.mode_click("collection")
    }else{
      this.getOtherpageList();
    }
    
    this.did = this.$route.query.did;
    console.log("wxl --- 444444",this.did)
   
    
  },
  methods: {
    createBtn(){
       this.$gtag.event('他人主页-create', { 'event_category': 'Click', 'event_label': 'createBtn' })
      this.createClick = true;
      this.onSaleClick = false;
      this.ownerClick = false;
      this.collectClick=false;
      this.filter = 10
      this.pageNumber = 1
      this.list =[]
      this.mode_click('nft')
      this.cardType = "other"
      this.collectsource = "firspublish"
      this.owerfrom="firspublish"
    } ,
    onSaleBtn(){
   this.$gtag.event('他人主页-onsale', { 'event_category': 'Click', 'event_label': 'onsale' })
    this.createClick = false;
      this.onSaleClick = true;
      this.ownerClick = false;
       this.collectClick=false;
      this.filter = 9
       this.pageNumber = 1
      this.list =[]
      this.mode_click('nft')
      this.cardType = "other"
      this.collectsource = "onsale"
      this.owerfrom="onsale"
      
    },
    ownerBtn(){
       this.$gtag.event('他人主页-owner', { 'event_category': 'Click', 'event_label': 'ownerBtn' })
       this.createClick = false;
      this.onSaleClick = false;
      this.ownerClick = true;
       this.collectClick=false;
      this.filter = 11
       this.pageNumber =1
      this.list =[]
       this.mode_click('nft')
       this.cardType = "other"
      this.collectsource = "offsale"
      this.owerfrom="offsale"

    },
        mousednft() {
      console.log("33333333");
      this.isshownft = true;
    },
    leavenft() {
      this.isshownft = false;
    },
    mousedcollect(){
this.isshowcollect = true
    },
     leavecollect() {
     this.isshowcollect = false
    },
    async getOtherpageList() {
      console.log("wxl --- getOtherpageList")
      this.isShowLoading = true;
      if (this.showModel == "nft") {
        let params = {
          pageSize: this.pageSize,
          pageNumber: this.pageNumber,
          owner: this.$route.query.did,
          filterType:this.filter
        };
        return api.home.getPersonnftList(params).then((res) => {
          let { list, totalPage } = res.data;
          this.totalPage = totalPage;
          if(this.pageNumber == 1) {
            this.nfts = [];
          }
          this.nfts = this.nfts.concat(list);
           if ((this.pageNumber== 1|| this.pageNumber== 0)&& list.length == 0) {
              this.empty = true;
            } else {
              this.empty = false;
            }
          this.isShowLoading = false;
          this.$emit('list',this.nfts)
        });
      } else if (this.showModel == "collection") {
        let params = {
          pageSize: this.pageSize,
          pageNumber: this.pageNumber,
          owner: this.$route.query.did,
        };
        return api.home.getPersoncollectionList(params).then((res) => {
          let { list, totalPage } = res.data;
          this.totalPage = totalPage;
          if(this.pageNumber == 1) {
            this.collects = [];
          }
          this.collects = this.collects.concat(list);
           if ((this.pageNumber== 1|| this.pageNumber== 0)&& list.length == 0) {
              this.empty = true;
            } else {
              this.empty = false;
            }
          this.isShowLoading = false;
        });
      }
    },
    mode_click(mode) {
       this.collectsource="otherpage"
      this.showModel = mode;
      if(this.showModel == 'collection'){
         this.$gtag.event('他人主页-collection', { 'event_category': 'Click', 'event_label': 'collection' })
      }
      mode == "nft"
        ? (this.nftImage = require("@/assets/icons/icon_cj_nft.png"))
        : (this.nftImage = require("@/assets/icons/icon_cj_nft_g.png"));
      mode == "collection"
        ? (this.collectionImage = require("@/assets/icons/icon_cj_collection.png"))
        : (this.collectionImage = require("@/assets/icons/icon_cj_collection_g.png"));
      this.nfts = [];
      this.collects = [];
      this.getOtherpageList();
      if( mode == "nft"){
        localStorage.setItem("selectMode",0)
      }else{
          this.createClick = false;
      this.onSaleClick = false;
      this.ownerClick = false;
       this.collectClick=true;
        localStorage.setItem("selectMode",1)
      }
    },
    onScroll(e) {
      
      const { scrollTop, clientHeight, scrollHeight } =
        e.target.scrollingElement;
      if (
        Math.ceil(scrollTop + clientHeight) >= scrollHeight &&
        this.totalPage > this.pageNumber
      ) {
        this.isShowLoading = true;
         this.pageNumber++
        this.getOtherpageList();
       
      } else if (this.totalPage == this.pageNumber) {
        this.isShowLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-start-center {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.empty {
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  /*width: 1220px;*/
  width: 100%;
  height: auto;
  margin-bottom: 51px;
  position:relative;

  .top-right {
    width: 80px;
    top: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    right: 0;
    // bottom: 0;
    .top_tab {
        .btn {
          height: 25px;
          font-family:Helvetica;
          font-size: 25px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 50px;
          letter-spacing: 0px;
          color: #766983;
          cursor:pointer;
        }
        .btn2 {
          height: 25px;
          font-family:Helvetica;
          font-size: 25px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 50px;
          letter-spacing: 0px;
          color:  #7800f4;
          border-bottom: 3px solid  #7800f4;
          padding-bottom: 8px;
        }
      }

    img {
      cursor: pointer;
    }
    .nft {
      width: 22px;
      height: 28px;
    }
    .collection {
      width: 25px;
      height: 25px;
      margin-left: 30px;
    }
  }
  .explain{
 
  .nftexplain{
       width: 130px;
      height: 31px;
      background-color: #270645;
      border-radius: 3px;
      position: absolute;
      top: 40px;
      left: 1037px;
      z-index:999;
    
      span {
        font-family:Helvetica;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 15px;
        letter-spacing: 0px;
        color: #ffffff;
        margin-left: 13px;
        margin-right: 13px;
         width: 115px;
      }
 
    }
       .collectexplain{
    
      height: 31px;
      background-color: #270645;
      border-radius: 3px;
      position: absolute;
      top: 40px;
      left: 1070px;
      z-index:1;
      span {
        font-family:Helvetica;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 15px;
        letter-spacing: 0px;
        color: #ffffff;
        margin-left: 13px;
        margin-right: 13px;
        width: 125px;
      }

    }

  
}
  .main {
    .collect {
      display: flex;
      flex-wrap: wrap;
      // justify-content: space-between;

      .card {
        margin-top: 16px;
        margin-bottom: 8px;
        margin-right: 30px;
        // justify-content: space-between;
        &:nth-child(5n) {
          margin-right: 0;
        }
      }
    }
  }

  &.mobile {
    .mr-8 {
          margin-right: 26px !important;
    }
    // .handler {
    //   position: relative;
    //   left: 160px;
    //   top: -40px;
    //   height: 41px;
    //   width: 80px;
    // }
  }
}

.mobile {
  .content .top-right .top_tab {
    .btn {
      font-size: 16px;
    }
    .btn2 {
      font-size: 16px;
    }
  
  }
  .collection{
    margin-left: 20px !important;
  
  }

  .card {
    width: 46%;
    margin-right: 20px !important;
    // justify-content: space-between;
    &:nth-child(2n) {
      margin-right: 0 !important;
    }
  }

  .collectionList {
    .card {
      width: 100%;
      margin-right: 0px !important;
    }
  }
 
}
</style>

