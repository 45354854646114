<template>
  <div :class="{ contain: true, mobile: isMobile }" >
    <header-page @getName="getName"></header-page>
    <div class="card">
      <tabs-page @list="getlist"></tabs-page>
      <div class="right_bottom" v-if="!isMobile">
        <img class="img" src="@/assets/icons/btn_play1.png" @click="openCarousel" alt="" />
        <div>
          <div @click="msgBtn">
            <img class="img" v-if="!openmsg" src="@/assets/icons/btn_message.png" alt="" />
            <span class="img" v-if="openmsg"></span> <!-- 占位用 -->
            <!-- <img v-if="openmsg" src="@/assets/icons/btn_close.png" alt="" /> -->
          </div>
          <div v-if="openmsg"
               style="position: fixed; right: 130px; bottom: 10px; z-index: 300;">
            <message @getOpen="OpenMsg" :currfriend="currfriend"></message>
          </div>
        </div>

      </div>
    </div>
    <v-dialog v-model="opencard">
      <carousel class="otherpage" :list="list" v-if="opencard" @getOpen="OpenCard"></carousel>
    </v-dialog>
  </div>
</template>

<script>
import HeaderPage from "./components/headerPage";
import TabsPage from "./components/tabsPage";
import Carousel from "../../components/carousel";
import message from "@/components/popup/message.vue";

export default {
  name: "otherpage",
  components: { Carousel, TabsPage, HeaderPage, message },
  data: () => ({
    opencard: false,
    open: false,
    openmsg: false,
    offset: true,
    pageInfo: {},
    did: "",
    currfriend: { },
    list: []
  }),
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  mounted() {
    this.did = this.$route.query.did;
    if(this.did == this.$store.status.did){
      this.$router.push({
          name: "PersonalPage",
          query: { did: this.did },
        });
    }
  

  },
  methods: {
    getName(e){
      this.currfriend.fansAddress = this.did;
      this.currfriend.fansName = e
    },
    //打开轮播图
    openCarousel() {
      this.opencard = true;
    },
    //弹框关闭后获取open值
    OpenCard(e) {
      this.opencard = e;
    },
    msgBtn() {
      this.openmsg = !this.openmsg;
    },
    OpenMsg(e) {
      this.openmsg = e;
    },
	getlist(dt) {
		this.list = dt;
	}
  },
};
</script>

<style lang="scss" scoped>
.contain {
  padding: 0;
  margin: 0;

  .card {
    /*max-height: 804px;*/
    height:auto;
    .right_bottom {
      position: fixed;
      bottom: 50px;
      right: 50px;
      z-index: 200;

      .img {
        display: block;
        width: 59px;
        height: 59px;
        margin-top: 35px;
      }
    }
  }

  &.mobile {
    width: 100%;
  }
}
</style>
